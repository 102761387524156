<template>
    <div>
        <div class="container footer-body">
            <div class="row">
                <div class="col-lg-5 col-sm-12 col-md-5">
                    <div class="footer-heading">
                        Truestock is your source of local stock images capturing candid life across all facets of life in Aotearoa, New Zealand.
                    </div>
                    <div class="footer-content">
                        We brief our photographers to capture entire narratives to create a series of stock images within each album. This unique approach helps us give you the widest selection of stock photos that capture the entire
                        narrative. Whether you're a marketer, designer, or content creator, Truestock offers the perfect authentic and inclusive stock images to enhance your projects.
                    </div>
                </div>
                <div class="col-lg-4 offset-lg-3 col-md-5 offset-md-2 col-sm-12">
                    <div id="footer-logo">
                        <img :src="`${assetsUrl}ts-logo-plain.svg`" alt="Truestock">
                    </div>
                    Email us at <a href="mailto:contact@truestock.co.nz">contact@truestock.co.nz</a> for any queries.
                    <div class="link-wrap">
                        <b-link v-b-modal.contact-modal>CONTACT</b-link>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                        <nuxt-link :to="{ name: 'about' }">ABOUT</nuxt-link>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                        <nuxt-link :to="{ name: 'pre-purchase' }">PRICING</nuxt-link>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                        <nuxt-link :to="{ name: 'tailored' }">
                            <nobr>CUSTOM CONTENT</nobr>
                        </nuxt-link>
                    </div>
                    <div class="social-icons">
                        <a target="_blank" href="https://www.instagram.com/truestock.co.nz/">
                            <b-icon-instagram/>
                        </a>
                        <a target="_blank" href="https://www.facebook.com/Truestock.co.nz">
                            <b-icon-facebook/>
                        </a>
                        <a target="_blank" href="https://www.linkedin.com/company/truestock-newzealand-stockphotos/">
                            <b-icon-linkedin/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="container text-center copyright pt-4">
            <a class="mb-2 d-inline-block" :href="$router.resolve({ name: 'terms-and-conditions' }).href" @click.prevent="$bvModal.show('terms-and-conditions-modal')">
                Terms &amp; Conditions
            </a>&nbsp;&nbsp;|&nbsp;&nbsp;<a class="mb-2 d-inline-block" :href="$router.resolve({ name: 'privacy-policy' }).href" @click.prevent="$bvModal.show('privacy-policy-modal')">
            Privacy Policy
        </a>&nbsp;&nbsp;|&nbsp;&nbsp;<a class="mb-2 d-inline-block" :href="$router.resolve({ name: 'content-licence-agreement' }).href" @click.prevent="$bvModal.show('content-licence-agreement-modal')">
            Content License Agreement
        </a>&nbsp;&nbsp;|&nbsp;&nbsp;<a class="mb-2 d-inline-block" href="https://truestock.com.au" target="_blank">
            Truestock Australia
        </a><br>Do not reproduce any media without the permission of Truestock Limited. All Rights Reserved.<br>&#169; Copyright Truestock Limited {{ $moment().year() }}
        </div>
    </div>
</template>
<script>
export default {
    name: 'NzFooterContent'
}
</script>
